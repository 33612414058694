import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import {
  Badge,
  ColorIcon,
  colors,
  customEvent,
  EventProps,
  getCdnImageSrc,
  GoiLogo,
  HStack,
  NextLink,
  useResponsive,
  VStack,
  MENU_FUNERAL_GUIDE_ITEMS,
  call,
} from "goi_common";

import { accountState } from "@/atoms/accountState";
import { useRouter } from "next/router";
import { ReactNode } from "react";
import { useRecoilValueLoadable } from "recoil";

import { Axios } from "@/utils/axios";
import dayjs from "dayjs";
import { useQuery } from "react-query";

interface FuneralGuideItemProps {
  title: string;
  image: string;
  event: EventProps;
  link: string;
  onClose: () => void;
}

interface MenuProps {
  isOpen: boolean;
  onClose: () => void;
}

interface DrawerItemProps {
  href: string;
  icon?: ReactNode;
  title: ReactNode;
  event?: EventProps;
}

function Menu({ isOpen, onClose }: MenuProps) {
  const router = useRouter();

  const { isBiggerThanMd } = useResponsive();

  const account = useRecoilValueLoadable(accountState);
  const login = () => {
    router.push(`/login?redirectTo=${encodeURIComponent(router.asPath)}`);
    onClose();
  };

  const DrawerItem = ({ href, icon, title, event }: DrawerItemProps) => {
    return (
      <DrawerItemRoot
        href={href}
        onClick={() => {
          onClose();
          event && customEvent(event);
        }}
      >
        {icon}
        <Box className="subtitle_14_b" marginLeft="12px">
          {title}
        </Box>
      </DrawerItemRoot>
    );
  };
  const { data } = useQuery(["funeral_reviews"], () => {
    return Axios.get("/sangjo_reviews/");
  });

  const viewReviewData = data?.data.results?.filter((item: any) => {
    return item.score >= 4;
  })[0];

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerOverlay bg="rgba(0, 0, 0, 0.5)" />
      <DrawerContent width="100%!important" maxWidth={isBiggerThanMd ? "360px" : "100%"}>
        <DrawerHeader
          flexDirection="column"
          display="flex"
          alignItems="flex-start"
          justifyContent="center"
          p="11px 20px 11px 28px"
          borderBottom={`1px solid ${colors.gray100}`}
        >
          <HStack justifyContent="space-between" alignItems="center" width="100%">
            <HStack alignItems="center" gap={24}>
              <HStack alignItems="center" gap={8}>
                <GoiLogo />
              </HStack>
            </HStack>
            <Button
              onClick={onClose}
              style={{
                width: "40px",
                height: "40px",
                padding: "0px",
                background: "none",
              }}
            >
              <ColorIcon name="header-close" size={20} color={colors.gray800} />
            </Button>
          </HStack>
        </DrawerHeader>
        {/* <DrawerSpacer /> */}

        <DrawerBody p="0">
          <Box
            padding="16px"
            borderRadius="6px"
            margin="24px 24px 12px 24px"
            // border={`1px solid ${colors.gray400}`}
            className="body3_r"
            display="flex"
            flexDirection="row"
            alignItems="center"
            backgroundColor={colors.gray50}
          >
            {account.state === "hasValue" && !account.contents ? (
              <HStack
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                onClick={login}
                color={colors.gray600}
              >
                <span
                  className="subtitle_16_sb"
                  css={{
                    backgroundColor: colors.gray50,
                  }}
                >
                  로그인 / 회원가입
                </span>{" "}
                <ColorIcon name="arrow_forward_rounded" size={14} color={colors.gray900} />
              </HStack>
            ) : (
              <HStack
                gap={6}
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                onClick={() => {
                  router.push("/my/");
                  customEvent({
                    newGtm: { path: "gnb", trigger_and_target: "mypage-uv_my" },
                  });
                  onClose();
                }}
                color={colors.gray700}
              >
                <HStack alignItems="center" gap="4px">
                  <span className="subtitle_16_sb gray900">{account.contents.nickname + "님"}</span>
                  <HStack alignItems="center" gap="4px">
                    <span className="body_16_r gray600">| 견적, 계약 확인</span>{" "}
                  </HStack>
                </HStack>
                <ColorIcon name="arrow_forward_rounded" size={14} color={colors.gray900} />
              </HStack>
            )}
          </Box>

          <VStack padding="0 24px 18px 0">
            <DrawerItem
              href="/service/"
              icon={
                <Box margin="0 0 0 4px">
                  <ColorIcon name="drawer_goi_icon" size={32} color={""} />
                </Box>
              }
              title={
                <HStack gap="6px" alignItems="center">
                  <Box className="body_16_r">고이상조 서비스 전체 보기</Box>
                  <Badge
                    className="caption_11_sb"
                    color={colors.orange700}
                    backgroundColor={colors.orange150}
                    css={{
                      borderRadius: "11px",
                      padding: "4px 6px",
                    }}
                  >
                    사전상담 할인가
                  </Badge>
                </HStack>
              }
              event={{
                newGtm: { path: "gnb", trigger_and_target: "drawer-service" },
              }}
            />

            <DrawerItem
              href="/match/request/"
              icon={
                <Box margin="0 0 0 4px">
                  <ColorIcon name="icon-document-search" size={32} color={""} />
                </Box>
              }
              title={<Box className="body_16_r">장례 전체 비용 견적, 맞춤형 혜택 알아보기</Box>}
              event={{
                newGtm: { path: "gnb", trigger_and_target: "drawer-mtch_rqst" },
              }}
            />

            <DrawerItem
              href="/guidebook/"
              icon={
                <Box margin="0 0 0 4px">
                  <ColorIcon name="book_has_bookmark" size={32} color="" />
                </Box>
              }
              title={<Box className="body_16_r">장례의 모든 정보, 무료 온라인 가이드북</Box>}
              event={{
                newGtm: { path: "gnb", trigger_and_target: "drawer-guidebook" },
              }}
            />
          </VStack>

          <DrawerSpacer />

          <VStack padding="24px" background={colors.white}>
            <HStack justifyContent="space-between" alignItems="center">
              <Box className="subtitle_18_sb gray900">
                고이상조 이용 후기
                {/* <sup className="subtitle_12_b orange650">NEW</sup> */}
              </Box>
              <HStack
                gap={6}
                css={{
                  cursor: "pointer",
                }}
                alignItems="center"
                onClick={() => {
                  router.push("/review/");
                  customEvent({
                    newGtm: {
                      path: "gnb",
                      trigger_and_target: "review-uv_review",
                    },
                  });

                  onClose();
                }}
              >
                <span className="body_14_r gray600">전체보기</span>
                <ColorIcon name="arrow_forward_rounded" color={colors.gray600} size={13} />
              </HStack>
            </HStack>

            <HStack gap={12} width="100%" margin="12px 0 0 0">
              {viewReviewData && (
                <VStack
                  padding="16px 20px"
                  background={colors.gray50}
                  borderRadius="6px"
                  width="100%"
                  gap="10px"
                  css={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    customEvent({
                      newGtm: {
                        path: "gnb",
                        trigger_and_target: "recent_review-uv_review_detail",
                        text: String(viewReviewData.id),
                      },
                      triggerHackle: true,
                    });
                    router.push(`/review/${viewReviewData.id}/`);
                    onClose();
                  }}
                >
                  <HStack alignItems="center" justifyContent="space-between">
                    <HStack alignItems="center" gap="4px">
                      <Box>
                        <ColorIcon name="rating_star" size={14} color={colors.orange650} />
                      </Box>
                      <Box className="subtitle_14_b gray900">{viewReviewData.score}.0</Box>

                      <Box className="caption_11_r gray500">
                        | {`${viewReviewData.user.age_and_gender} ` || ""}
                        {viewReviewData.user.nickname}님
                      </Box>
                    </HStack>
                    <Box
                      className={"caption_11_r"}
                      color={getDate(viewReviewData.created_at).color}
                    >
                      {getDate(viewReviewData.created_at).text}
                    </Box>
                  </HStack>

                  <VStack position="relative" gap="4px">
                    <Box
                      className="body_14_r gray900"
                      css={{
                        display: "-webkit-box",
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        maxWidth: "100%",
                      }}
                    >
                      {viewReviewData.good}
                    </Box>
                    {viewReviewData.good.length > 80 && (
                      <Box
                        className="caption_12_r gray500"
                        cursor="pointer"
                        onClick={() => {
                          customEvent({
                            newGtm: {
                              path: "gnb",
                              trigger_and_target: "recent_review-uv_review_detail",
                              text: String(viewReviewData.id),
                            },
                            triggerHackle: true,
                          });
                          router.push(`/review/${viewReviewData.id}/`);
                          onClose();
                        }}
                      >
                        더보기
                      </Box>
                    )}
                  </VStack>
                </VStack>
              )}
            </HStack>
          </VStack>

          <DrawerSpacer />

          <VStack overflow="hidden" padding="24px">
            <Box className="subtitle_18_sb">장례 준비, 제일 궁금한 점들을 모았어요</Box>

            <Box overflow="scroll">
              <HStack overflow="scroll" width="max-content" padding="16px 0px" gap="16px">
                {MENU_FUNERAL_GUIDE_ITEMS.map((item) => (
                  <MenuFuneralGuideItem key={item.link} {...item} onClose={onClose} />
                ))}
              </HStack>
            </Box>
          </VStack>
          <DrawerSpacer />
        </DrawerBody>

        <DrawerFooter padding="16px 24px">
          <VStack
            width="100%"
            height="60px"
            alignItems="center"
            justifyContent="center"
            background={colors.orange650}
            borderRadius="5px"
            color={colors.white}
            css={{
              cursor: "pointer",
            }}
            onClick={() => {
              call("1666-9784", true);
            }}
          >
            <HStack color={colors.white} alignItems="center" gap="6px">
              <ColorIcon color={colors.white} name="icon-call-mono" size={20} />
              <Box className="button_16_sb white">24시간 상담 / 임종 접수</Box>
            </HStack>
          </VStack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

const DrawerSpacer = styled.div`
  width: 100%;
  height: 6px;
  background: var(--gray50);
`;

const DrawerItemRoot = styled(NextLink)`
  margin-left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  padding-left: 20px;
  = :active {
    background: var(--gray50);
  }

  .body3_m {
    padding-left: 12px;
  }
`;

const getDate = (date: Date) => {
  const created = dayjs(date);
  const now = dayjs();

  const diffDays = now.diff(created, "day");
  const diffHours = now.diff(created, "hour");
  const diffMinutes = now.diff(created, "minute");

  if (diffDays < 1) {
    if (diffHours < 1) {
      return { text: `${diffMinutes}분 전`, color: colors.orange650 };
    } else {
      return { text: `${diffHours}시간 전`, color: colors.orange650 };
    }
  } else if (diffDays < 4) {
    return { text: `${diffDays}일 전`, color: colors.orange650 };
  } else {
    return { text: created.format("YYYY.MM.DD"), color: colors.gray500 };
  }
};

const MenuFuneralGuideItem = ({ title, image, event, link, onClose }: FuneralGuideItemProps) => {
  const router = useRouter();

  return (
    <VStack
      width="120px"
      borderRadius="6px"
      onClick={() => {
        router.push(link);
        customEvent(event);
        onClose();
      }}
      css={{
        cursor: "pointer",
      }}
    >
      <Box
        borderRadius="6px"
        width="100%"
        height="120px"
        backgroundImage={getCdnImageSrc(image)}
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
      />
      <Box width="100%" padding="8px 0px" className="body_14_r gray900">
        {title}
      </Box>
    </VStack>
  );
};

export default Menu;
