import { EventProps } from "../utils";

export const MENU_FUNERAL_GUIDE_ITEMS = [
  {
    title: "처음하는 장례 준비, 어떻게 시작해야할까요?",
    image: "platform/prepare/prepare-1-2.png",
    link: "/funeral-prepare/1/",
    event: {
      newGtm: {
        path: "gnb",
        trigger_and_target: "content-uv_prepare_1",
      },
    },
  },
  {
    title: "장례식장에서 장례를 치를 때, 빈소 계약시 주의할 점",
    image: "platform/prepare/prepare-1-7.png",
    link: "/guide/how-about-parlor-funeral/",
    event: {
      newGtm: {
        path: "gnb",
        trigger_and_target: "content-uv_how_about_parlor_funeral",
      },
    },
  },
  {
    title: "상조 없이 장례식장에서 장례를 진행하면 더 저렴할까요?",
    image: "platform/prepare/prepare-1-1-1.png",
    link: "/guide/about-parlor-funeral/",
    event: {
      newGtm: {
        path: "gnb",
        trigger_and_target: "content-uv_about_parlor_funeral",
      },
    },
  },
  {
    title: "선불제 상조보다 100만~200만원 저렴한 후불제 상조에 대해 알아보기",
    image: "platform/home/carousel-2.png",
    link: "/funeral-prepare/2/",
    event: {
      newGtm: {
        path: "gnb",
        trigger_and_target: "content-uv_prepare_2",
      },
    },
  },
  {
    title: "투명하고 정직한 장례. 고이장례연구소에 대해 알아보기",
    image: "images/company-introduction/company-introduction-1.png",
    link: "/funeral-prepare/company-introduction/",
    event: {
      newGtm: {
        path: "gnb",
        trigger_and_target: "content-uv_company_introduction",
      },
    },
  },
  {
    title:
      "고이장례연구소에서는 장례 비용을 어떻게 500만원, 1000만원까지 줄일 수 있나요?",
    image: "platform/home/carousel-1.png",
    link: "/funeral-prepare/funeral-cost/",
    event: {
      newGtm: {
        path: "gnb",
        trigger_and_target: "content-uv_funeral_cost",
      },
    },
  },
] as {
  title: string;
  image: string;
  event: EventProps;
  link: string;
}[];
